/* global URL */

(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name wbOrder.controller:FileUploadCtrl
   *
   * @description
   *
   */
  angular
    .module('wbOrder')
    .controller('FileUploadCtrl', FileUploadCtrl);

  function FileUploadCtrl(appConfig,
    orderType,
    FormControl,
    $q,
    _,
    Cart,
    $scope,
    OrderService,
    $mdDialog,
    $document,
    $timeout,
    $translate) {
    var vm = this
      , formControl = new FormControl()
      , deferred
      , availableFile = {}
      , downloadableBlob = ''
      , fileRelatedError
      , EdenredECSselectedProductType = 'EdenredEcsSelectedProductType'
      , selectedProductType = localStorage.getItem(EdenredECSselectedProductType) === 'default' ? 'benefit' : localStorage.getItem(EdenredECSselectedProductType)
      , newCardSample = '/files/rewin-minta_kartya_rendeles_' + selectedProductType + '.xlsx'
      , topUpSample = '/files/rewin-minta_kartya_feltoltes_' + selectedProductType + '.xlsx';

    $scope.$on('$stateChangeSuccess', onStateChangeSuccess);

    vm.sampleFile = (orderType === '1') ? newCardSample : topUpSample;

    vm.fileRelatedError = false;

    vm.getSelectedProductType = function getSelectedProductType() {
      return selectedProductType;
    };

    vm.invalidateFile = function invalidateFile(fileName) {
      availableFile.name = fileName || '';
      availableFile.valid = false;
    };

    vm.invalidateFile();

    vm.sendToAPI = function sendToAPI($files, $event, $flow) {
      deferred = $q.defer();
      formControl.process(deferred.promise);
      $flow.opts.target = appConfig.api.url + 'carts/' + orderType + '/files';
      $flow.upload();
    };

    vm.handleAPIErrors = function handleAPIErrors($files, $message, $flow) {
      /*jshint unused:false */
      /*eslint "no-unused-vars": [2, {"args": "none"}]*/
      var response = {};

      vm.invalidateFile($files.name);
      response.status = 400;
      response.data = _.defaultsDeep({}, angular.fromJson($message));
      handleErrorDialog(response.data.error);
      deferred.reject(response);
    };

    vm.handleAPISuccess = function handleAPISuccess($files, $message, $flow) {
      /*jshint unused:false */
      /*eslint "no-unused-vars": [2, {"args": "none"}]*/
      var response = _.defaultsDeep({}, angular.fromJson($message));

      availableFile.name = response.flowFilename;
      availableFile.valid = true;
      fileRelatedError = false;
      OrderService.updateCart();
      updateDownloadableBlob();
      deferred.resolve();
    };

    vm.deleteFile = function deleteFile() {
      $mdDialog
        .show(
          $mdDialog
            .confirm({ focusOnOpen: false })
            .parent(angular.element($document.body))
            .clickOutsideToClose(true)
            .content($translate.instant('order.bulk.files.delete.dialog', { fileName: vm.getAvailableFileName() }))
            .ok($translate.instant('order.itemDialog.delete'))
            .cancel($translate.instant('order.itemDialog.cancel'))
        )
        .then(function () {
          return Cart.deleteFile({
            cartId: orderType,
            fileName: vm.getAvailableFileName()
          }).$promise;
        })
        .then(function () {
          vm.invalidateFile();
          $timeout(function () {
            OrderService.updateCart();
          }, 1000);
        });
    };

    vm.hasFile = function hasFile() {
      return !_.isEmpty(availableFile.name);
    };

    vm.isValidFile = function isValidFile() {
      return availableFile.valid;
    };

    vm.isProcessing = function isProcessing() {
      return formControl.isProcessing();
    };

    vm.getServerValidation = function getServerValidation() {
      return formControl.getServerValidation();
    };

    vm.order = {
      status: 'PRG',
      type: orderType
    };

    vm.getAvailableFileName = function getAvailableFileName() {
      return availableFile.name;
    };

    vm.getUploadedFileUrl = function getUploadedFileUrl() {
      return downloadableBlob;
    };

    vm.openSampleAlert = function openSampleAlert() {
      $mdDialog
        .show(
          $mdDialog
            .alert({ focusOnOpen: false })
            .parent(angular.element($document.body))
            .clickOutsideToClose(true)
            .content($translate.instant('order.sampleFileDownloadAlert'))
            .ok($translate.instant('order.itemDialog.close'))
        );
    };

    vm.fileRelatedError = function () {
      return fileRelatedError;
    };

    function updateDownloadableBlob() {
      if (vm.getAvailableFileName() === '') {
        return vm.getAvailableFileName();
      }

      Cart
        .getFile({
          cartId: orderType,
          fileName: vm.getAvailableFileName()
        })
        .$promise
        .then(function (data) {
          downloadableBlob = URL.createObjectURL(data.response);
        })
        .catch(function () {
          $timeout(updateDownloadableBlob, 1000);
        });
    }

    function onStateChangeSuccess() {
      Cart
        .get({ cartId: orderType })
        .$promise
        .then(function (cart) {
          if (!_.isNull(cart.order)) {
            _.forEach(cart.order.items, function (n) {
              if (n.type === 2) {
                availableFile.name = n.fileName.split('\\')[1];
                availableFile.valid = true;
              }
            });
          }
        })
        .then(updateDownloadableBlob);
    }

    function handleErrorDialog(errors) {
      fileRelatedError = !_.isUndefined(errors.file) ? Object.keys(errors.file) : false;

      if (!fileRelatedError) {
        $mdDialog.show({
          controller: 'FileBulkErrorDialogCtrl',
          controllerAs: 'fileBulkErrorDialog',
          templateUrl: 'wb-order/views/fileBulkErrorDialog.tpl.html',
          parent: angular.element($document.body),
          bindToController: true,
          locals: {
            errors: errors
          },
          focusOnOpen: false
        });
      }
    }
  }
}());
